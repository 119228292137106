/*HEADER & FOOTER*/

header {
  border-bottom: 2px solid #fff;
  /* height: 120px; */
}

header h1 {
  color: white;
  font-size: 1.5em;
  width: 90%;
  margin: 0 auto;
}

footer {
  border-top: 2px solid #fff;
}

footer p {
  width: 90%;
  margin: 0 auto;
  color: #fff;
  text-align: center;
}

footer a {
  color: #fff;
}

/*SECTIONS*/

.profilePageBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
  padding-top: 3rem;
  justify-content: center;
}

section {

  /* flex-flow: row wrap;
  width: 100%;
  padding-bottom: 20px;
  margin: 20px auto; */

}

.profilePageBody h2 {
  margin: 20px 0;
  width: 90%;
}

.profilePageBody>div {
  box-shadow: 0px 0px 10px #bbb;
  background-color: #fff;
  /* width: 90%; */
  /* margin: 0 auto; */
  padding-left: 20px;
}

/*SECTION INFOS*/




.prez {
  border-bottom: 2px dashed #ccc;
}

.prez a {
  display: block;
  text-align: center;
  padding: 10px;
  margin: 10px auto 30px auto;
  color: #fff;
  background-color: #EA0;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 5px;
}

.contact-flex {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1rem;
}

.contact-flex p:first-child {
  flex: 0 0 40%;
}

.contact-flex p:last-child {
  flex: 0 0 60%;
}

.social {
  display: flex;
  justify-content: space-around;
  margin: 10px auto 0px auto;
}

.social img {
  width: 40px;
  height: 40px;
}

/*SECTION EXP ET FORMATION*/
.exp {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid #bbb;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.exp-info {
  display: flex;
  flex-flow: column wrap;
}

.exp-logo {
  flex: 0 0 25%;
  max-width: 100px;
}

.exp-info {
  flex: 0 0 70%;
  margin-left: auto;
}

.exp img {
  width: 100%;
}

.exp h3 {
  font-size: 1.2em;
}

.exp h4 {
  font-size: 1em;
  font-weight: normal;
}

/*SECTION COMPETENCES*/
.h3gauche {
  width: 90%;
}

.comp {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
}

.comp p {
  flex: 0 0 40%;
}

.conteneur-barre {
  flex: 0 0 60%;
  border-radius: 5px;
  background-color: grey;
  border-bottom: 1px ridge grey;
}

.barre {
  display: block;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.c85 {
  width: 85%;
  background-color: #8B8;
}

.c90 {
  width: 90%;
  background-color: #4B8;
}

.c95 {
  width: 95%;
  background-color: #4B8;
}

.c100 {
  width: 100%;
  background-color: #4B8;
  border-radius: 5px;
}

.comp2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.conteneur-barre2 {
  flex: 0 0 100%;
  height: 0.5em;
  margin-bottom: 0.25em;
  border-radius: 5px;
  background-color: grey;
}

/*SECTION INTERETS*/
.interet {
  flex: 0 1 50%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: 20px;
}

.interet img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 0px 15px #555;
}

/*VERSION BUREAU DU CV*/
@media screen and (min-width: 980px) {
  /* section {
    width: 80%;
    box-shadow: 0px 0px 10px #bbb;
  } */

  a:hover {
    color: #EA0;
  }

  header h1 {
    width: 80%;
  }

  .prez, .contact {
    flex: 0 0 45%;
  }

  .prez {
    border-bottom: none;
    border-right: 2px solid #ccc;
    padding-right: 20px;
    margin-left: auto;
  }

  .contact {
    padding-left: 20px;
    margin-right: auto;
  }

  .prez a {
    border: 2px solid transparent;
  }

  .prez a:hover {
    color: #f28835;
    background-color: #fff;
    border: 2px solid #f28835;
    box-shadow: 0px 0px 20px #666;
  }

  .exp-logo {
    flex: 0 0 10%;
  }

  .exp-info, .exp-desc {
    flex: 0 0 85%;
    margin-left: auto;
  }

  .interet {
    flex: 0 1 25%;
  }

  .ProfilePage {
    position: relative;
  }

  .photo {
    text-align: center;
    position: absolute;
    top: 50px;
    right: 50%;
    transform: translateX(50%);
  }

  .photo p {
    font-size: 18px;
  }

  .camera {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 1.7rem;
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
  }

  /* .photo img {
    width: 150px;
    height: 150px;
    border: 3px solid #fff;
    box-shadow: 0px 0px 10px #777;
    border-radius: 50%;
  } */

  .imageBanner {

    height: 300px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    filter: brightness(1.1);

  }
}

.ProfilePage .MuiOutlinedInput-root {

  margin: 5px 0 !important;
}