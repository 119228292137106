.smallerNotification {
  height: 200px;
  overflow: scroll;
  /* transform: scale(0.8); */

}

.smallerNotification .filtersContainer{
  display: none !important;
}


.smallerNotification .element{
  height: 75px;
  /* display: none !important; */
}


.smallerNotification .notificationPage {
  padding: 5px;

}

.smallerNotification .notificationPage img{
width: 50px;
}